import './bootstrap';
import './cookieinfo.min';
import './fittext';
import jQuery from 'jquery';

import Swiper, { Autoplay, Pagination } from "swiper";

const swiperHome = new Swiper(".swiper-home", {
    modules: [Autoplay, Pagination],
    slidesPerView: 1.5,
    centeredSlides: true,
    spaceBetween: 0,
    speed: 5000,
    loop: true,
    autoplay: {
        delay: 0,
        pauseOnMouseEnter: true,
        disableOnInteraction: false,
    },
    pagination: {
        el: ".swiper-pagination",
    },
    breakpoints: {
        768: {
            slidesPerView: 2.5,
        },
        1400: {
            slidesPerView: 4,
        },
    },
});

const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            entry.target.classList.add('animation');
        }
    });
});

//Prüft vorher ob das Element existiert
var balloon = document.getElementById('balloon');
if (typeof(balloon) != 'undefined' && balloon != null)
{
    observer.observe(document.querySelector('#balloon'));
}

window.$ = jQuery;
window.jQuery = window.$ = $

var app = {

	settings: {},

    init: function() {
		var s = this.settings;
		$ = jQuery;
        this.twoClickReplace();
        this.validateCaptcha();
        this.initFitTextOnEventBanner();
    },

    /**
     * Blocks content within a template tag and unveils it, when user clicks on the accept button.
     */
    twoClickReplace: function() {
		var twoclick_replace = function($container) {
			var h = $container.data('preserveheight') ? $container.height() : 0;
			$container.html($('<div>'+$container.find('template').html()+'</div>')).addClass('replaced');
			h ? $container.css('min-height', h+'px') : null;
		};

		if(typeof sessionStorage !== "undefined") {
			  var tc_pref = sessionStorage.getItem('tc_pref');
			  tc_pref = tc_pref ? JSON.parse(tc_pref) : {};
			$('.twoclick').each(function() {
				var $container = $(this),
				network = $container.data('network');

				if(tc_pref[network]) {
				twoclick_replace($container);
					  }
				  });
		}

		$('.twoclick button').on('click', function() {
			  var $container = $(this).parents('.twoclick'),
			  network = $container.data('network');

			twoclick_replace($container);

			if(typeof sessionStorage !== "undefined") {
			  tc_pref[network] = 1;
			  sessionStorage.setItem('tc_pref', JSON.stringify(tc_pref));
			}
		});
	},

    validateCaptcha: function () {
        $('#submitForm').on('click', function (e) {
            e.preventDefault();

            // check if everything was filled out properly (email field & captcha)
            if (!document.getElementById("contactForm").reportValidity()) {
                return;
            }

            // name of image radio buttons from captcha
            const image_name = "image_captcha"
            const correct_image_name = "image_captcha_correct"

            let numberOfImages = $('input[name="image_captcha"]').length;
            let selectedImage = $('input[name="' + image_name + '"]:checked').val();
            let correctImage = $('input[name="' + correct_image_name + '"]').val();

            // no picture selected
            if (typeof selectedImage == 'undefined') {
                return;
            }

            // prepare data for ajax request
            let data = [
                {
                    name: "ic_input", // user selected image
                    value: selectedImage
                },
                {
                    name: "ic_correct", // correct image
                    value: correctImage
                },
                {
                    name: "ic_amount_of_images", // number of images in captcha
                    value: numberOfImages
                },
                {
                    name: "ic_image_name", // name of image field
                    value: "image_captcha"
                },
                {
                    name: "ic_correct_image_name", // name of correct image field
                    value: "image_captcha_correct"
                }
            ];
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax({
                url: "/captcha-controller",
                data: data,
                type: 'GET',
                dataType: 'json',
                success: function (response) {
                    // Check if request was valid.
                    // Set new captcha when validation fails
                    if ( response.success == false ) {
                        $(".fp-image-captcha").html(response.captcha);
                    }
                    else {
                        $("#contactForm").trigger('submit'); // Submit form.
                    }

                    return;
                }
            });
        });
    },

    initFitTextOnEventBanner: function() {
        if ( $( '.teaser-video__plate__text' ).length ) {
            window.fitText( $( '.teaser-video__plate__text' ), 0.75 );
        }
    },

}

$( document ).ready( function( $ ) {

	'use strict';

	app.init();

} )
